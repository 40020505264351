import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuData: {
      main: {
        id: 'Main',
        parent: null,
        icon: 'startseite',
        title: null,
        text: null,
        color: '#7F7A6E', // == neutral
        elements: ['school', 'parents', 'teachers', 'support']
      },
      school: {
        id: 'School',
        parent: 'main',
        icon: 'schule',
        title: 'Unsere Schule',
        text: null,
        color: '#0d5494',
        elements: ['staff', 'building', 'departments', 'history']
      },
      staff: {
        id: 'Staff',
        parent: 'school',
        icon: 'schulgemeinschaft',
        title: 'Schulgemeinschaft',
        text: null,
        color: '#1170C4',
        position: 1
      },
      building: {
        id: 'Building',
        parent: 'school',
        icon: 'schulhaus',
        title: 'Schulhaus',
        text: null,
        color: '#1B8AEB',
        position: 2
      },
      departments: {
        id: 'Departments',
        parent: 'school',
        icon: 'abteilungen',
        title: 'Abteilungen',
        text: null,
        color: '#49A1EF',
        position: 3
      },
      history: {
        id: 'History',
        parent: 'school',
        icon: 'historisches',
        title: 'Historisches',
        text: null,
        color: '#76B9F3',
        position: 4
      },
      parents: {
        id: 'Parents',
        parent: 'main',
        icon: 'eltern',
        title: 'Für Schüler*innen und Eltern',
        text: null,
        color: '#9E5E24', // '#76b729',
        elements: ['values', 'projects', 'faq', 'service']
      },
      values: {
        id: 'Values',
        parent: 'parents',
        icon: 'werte',
        title: 'Unsere Werte',
        text: null,
        color: '#B06A28', // '#8CD237',
        position: 1
      },
      projects: {
        id: 'Projects',
        parent: 'parents',
        icon: 'projekte',
        title: 'Projekte',
        text: null,
        color: '#C3752C', // '#9FD959',
        position: 2
      },
      faq: {
        id: 'FAQ',
        parent: 'parents',
        icon: 'fragen',
        title: 'Fragen & Antworten',
        text: null,
        color: '#D18035', // '#B3E17A',
        position: 3
      },
      service: {
        id: 'Service',
        parent: 'parents',
        icon: 'service',
        title: 'Service',
        text: null,
        color: '#D58C47', // '#C6E89B',
        position: 4
      },
      teachers: {
        id: 'Teachers',
        parent: 'main',
        icon: 'lehrkraefte',
        title: 'Für Lehrkräfte',
        text: null,
        color: '#2A9155', // '#6AC992',
        elements: ['goals', 'tService', 'tFaq']
      },
      goals: {
        id: 'Goals',
        parent: 'teachers',
        icon: 'ziele',
        title: 'Unsere Ziele',
        text: null,
        color: '#34B56A', // '#7ED1A1',
        position: 1
      },
      tFaq: {
        id: 'TFAQ',
        parent: 'teachers',
        icon: 'tfragen',
        title: 'Fragen & Antworten',
        text: null,
        color: '#4DCB81', // '#94D8B0',
        position: 2
      },
      tService: {
        id: 'TService',
        parent: 'teachers',
        icon: 'tservice',
        title: 'Service',
        text: null,
        color: '#71D69B',
        position: 3
      },
      support: {
        id: 'Support',
        parent: 'main',
        icon: 'mithelfen',
        title: 'Mithelfen & Spenden',
        text: null,
        color: '#007EC5',
        elements: ['klinikschulverein', 'dazugehoeren']
      },
      klinikschulverein: {
        id: 'KlinikSchulVerein',
        parent: 'support',
        icon: 'klinikschulverein',
        title: 'KlinikSchulVerein Ulm e.V.',
        text: null,
        color: '#009AF3',
        position: 1
      },
      dazugehoeren: {
        id: 'Dazugehoeren',
        parent: 'support',
        icon: 'dazugehoeren',
        title: 'Dazugehören e.V.',
        text: null,
        color: '#21AEFF',
        position: 2
      },
      contact: {
        id: 'Contact',
        parent: 'main',
        icon: 'kontakt',
        title: 'Kontakt',
        text: null,
        color: '#7F7A6E', // neutral
        position: 2
      },
      imprint: {
        id: 'Imprint',
        parent: 'main',
        icon: 'impressum',
        title: 'Impressum',
        text: null,
        color: '#7F7A6E', // neutral
        position: 3
      },
      privacy: {
        id: 'Privacy',
        parent: 'main',
        icon: 'datenschutz',
        title: 'Datenschutz',
        text: null,
        color: '#7F7A6E', // neutral
        position: 3
      }
    },
    staff: {
      executives: [
        {
          fullName: 'Daniela Kloppert',
          title: 'Schulleiterin',
          img: '/img/persons/d-kloppert.jpg',
          phone: '+49 731 500 69318',
          mail: 'daniela.kloppert@uniklinik-ulm.de',
          fax: null,
          position: 1
        },
        {
          fullName: 'Lucia Geitmann',
          title: 'Stv. Schulleiterin',
          img: '/img/persons/geitmann.jfif',
          phone: '+49 731 500 69331',
          mail: 'lucia.geitmann@uniklinik-ulm.de',
          fax: null,
          position: 2
        }
      ],
      office: [
        {
          fullName: 'Ariane Saccarello',
          title: 'Sekretärin',
          img: '/img/persons/saccarello.jfif',
          phone: '+49 731 / 500 69301',
          mail: 'kranke-son@ulm.de',
          fax: '+49 731 / 500 69302',
          position: 1
        }
      ],
      collegium: [
        {
          name: 'Unterstufe',
          description: 'Abteilung KJPP / Klasse 1 bis 4',
          position: 1,
          teachers: [
            {
              fullName: 'Franziska Klein',
              title: 'Sonderschullehrerin',
              img: '/img/persons/f-klein.jpg',
              phone: null,
              mail: 'f.klein@hls-ulm.de',
              fax: null,
              position: 1
            },
            {
              fullName: 'Jan Magera',
              title: 'Grundschullehrer',
              img: '/img/persons/j-magera.jpg',
              phone: null,
              mail: 'j.magera@hls-ulm.de',
              fax: null,
              position: 2
            },
            {
              fullName: 'Nina Harfmann',
              title: 'Grundschullehrerin',
              img: '/img/persons/n-harfmann.jpg',
              phone: null,
              mail: 'n.harfmann@hls-ulm.de',
              fax: null,
              position: 3
            },
            {
              fullName: 'Manuela Bochtler',
              title: 'Realschullehrerin',
              img: null,
              phone: null,
              mail: 'm.bochtler@hls-ulm.de',
              fax: null,
              position: 4
            }
          ]
        },
        {
          name: 'Mittelstufe',
          description: 'Abteilung KJPP / Klasse 5 bis 8',
          position: 2,
          teachers: [
            {
              fullName: 'Heike Albrecht',
              title: 'Sonderschullehrerin',
              img: '/img/persons/h-albrecht.jpg',
              phone: null,
              mail: 'h.albrecht@hls-ulm.de',
              fax: null,
              position: 1
            },
            {
              fullName: 'Kristin Kessler',
              title: 'Gymnasiallehrerin',
              img: '/img/persons/k-kessler.jpg',
              phone: null,
              mail: 'k.kessler@hls-ulm.de',
              fax: null,
              position: 2
            },
            {
              fullName: 'Sandra Mahringer',
              title: 'Realschullehrerin',
              img: '/img/persons/s-mahringer.jpg',
              phone: null,
              mail: 's.mahringer@hls-ulm.de',
              fax: null,
              position: 3
            },
            {
              fullName: 'Dietmar Raschke',
              title: 'Realschullehrer',
              img: '/img/persons/d-raschke.jpg',
              phone: null,
              mail: 'd.raschke@hls-ulm.de',
              fax: null,
              position: 4
            },
            {
              fullName: 'Klaus Lange',
              title: 'Realschullehrer',
              img: null,
              phone: null,
              mail: 'k.lange@hls-ulm.de',
              fax: null,
              position: 5
            },
            {
              fullName: 'Jana Trostel',
              title: 'Realschullehrerin',
              img: '/img/persons/j-trostel.jpg',
              phone: null,
              mail: 'j.trostel@hls-ulm.de',
              fax: null,
              position: 6
            }
          ]
        },
        {
          name: 'Oberstufe',
          description: 'Abteilung KJPP / Klasse 8 bis 12 und Berufsschule',
          position: 3,
          teachers: [
            {
              fullName: 'Verena Baur',
              title: 'Gymnasiallehrerin',
              img: null,
              phone: null,
              fax: null,
              position: 1
            },
            {
              fullName: 'Mark Brickwedde',
              title: 'Gymnasiallehrer',
              img: '/img/persons/m-brickwedde.jpg',
              phone: null,
              mail: 'm.brickwedde@hls-ulm.de',
              fax: null,
              position: 2
            },
            {
              fullName: 'Dolores Hallmen',
              title: 'Gymnasiallehrerin',
              img: null,
              phone: null,
              mail: 'd.hallmen@hls-ulm.de',
              fax: null,
              position: 3
            },
            {
              fullName: 'Michaela Lange',
              title: 'Realschullehrerin',
              img: null,
              phone: null,
              mail: 'm.lange@hls-ulm.de',
              fax: null,
              position: 4
            },
            {
              fullName: 'Christina Müller',
              title: 'Gymnasiallehrerin',
              img: null,
              phone: null,
              mail: 'c.mueller@hls-ulm.de',
              fax: null,
              position: 5
            },
            {
              fullName: 'Dietmar Prinzing',
              title: 'Sonderschullehrer',
              img: '/img/persons/d-prinzing.jpg',
              phone: null,
              mail: 'd.prinzing@hls-ulm.de',
              fax: null,
              position: 6
            },
            {
              fullName: 'Nataliya Stiefenhofer',
              title: 'Lehrerin',
              img: null,
              phone: null,
              mail: 'n.stiefenhofer@hls-ulm.de',
              fax: null,
              position: 7
            },
            {
              fullName: 'Jana Leger',
              title: 'Gymnasiallehrerin',
              img: '/img/persons/j-leger.jpg',
              phone: null,
              mail: 'j.leger@hls-ulm.de',
              fax: null,
              position: 8
            },
            {
              fullName: 'Franziska Reif',
              title: 'Realschullehrerin',
              img: null,
              phone: null,
              mail: 'f.reif@hls-ulm.de',
              fax: null,
              position: 8
            }
          ]
        },
        {
          name: 'Beratung und Begleitung ambulanter Schüler',
          description: null,
          position: 4,
          teachers: [
            {
              fullName: 'Lucia Geitmann',
              title: 'Sonderschullehrerin',
              img: '/img/persons/geitmann.jfif',
              phone: null,
              mail: 'l.geitmann@hls-ulm.de',
              fax: null,
              position: 1
            }
          ]
        },
        {
          name: 'Unterricht am Krankenbett',
          description: null,
          position: 5,
          teachers: [
            {
              fullName: 'Heike Albrecht',
              title: 'Sonderschullehrerin, Leitung Abteilung Unterricht am Krankenbett',
              img: '/img/persons/h-albrecht.jpg',
              phone: null,
              mail: 'h.albrecht@hls-ulm.de',
              fax: null,
              position: 1
            },
            {
              fullName: 'Eva Hölz-Reisch',
              title: 'Gymnasiallehrerin',
              img: '/img/persons/e-hoelz-reisch.jpg',
              phone: null,
              mail: 'e.hoelz@hls-ulm.de',
              fax: null,
              position: 2
            },
            {
              fullName: 'Birgit Schramm',
              title: 'Sonderschullehrerin',
              img: null,
              phone: null,
              mail: 'b.schramm@hls-ulm.de',
              fax: null,
              position: 3
            },
            {
              fullName: 'Dr. Simone Hein-Khatib',
              title: 'Lehrerin',
              img: '/img/persons/s-hein-khatib.jpg',
              phone: null,
              mail: 's.hein-khatib@hls-ulm.de',
              fax: null,
              position: 5
            },
            {
              fullName: 'Kerstin von Hippel',
              title: 'Gymnasiallehrerin',
              img: null,
              phone: null,
              mail: 'k.vonhippel@hls-ulm.de',
              fax: null,
              position: 6
            },
            {
              fullName: 'Hannah Gelhaus',
              title: 'Sonderschullehrerin',
              img: '/img/persons/h-gelhaus.jpg',
              phone: null,
              mail: 'h.gelhaus@hls-ulm.de',
              fax: null,
              position: 7
            },
            {
              fullName: 'Maria Edelmann',
              title: 'Grundschullehrerin',
              img: '/img/persons/m-edelmann.jpg',
              phone: null,
              mail: 'm.edelmann@hls-ulm.de',
              fax: null,
              position: 8
            }
          ]
        }
      ]
    }
  },
  mutations: {
  },
  getters: {
    menuData: state => {
      return state.menuData
    },
    staff: state => {
      return state.staff
    }
  },
  actions: {
  },
  modules: {
  }
})
