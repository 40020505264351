var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-2",attrs:{"color":"#0000001A","rounded":""}},[_c('v-card',{staticClass:"neutral--text",attrs:{"light":"","elevation":"0"}},[_c('v-img',{attrs:{"aspect-ratio":4/5,"src":_vm.person.img},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-sheet',{staticClass:"fill-height",attrs:{"color":"#eee","tile":""}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"size":"120"}},[_vm._v(" mdi-account ")])],1)],1)]},proxy:true}])}),_c('v-card-title',{staticClass:"font-weight-bold pb-0",class:{
        'text-body-2': _vm.$vuetify.breakpoint.xs,
        'text-body-1': _vm.$vuetify.breakpoint.sm,
        'px-2': _vm.$vuetify.breakpoint.xs,
        'px-3': _vm.$vuetify.breakpoint.sm
      }},[_vm._v(" "+_vm._s(_vm.person.fullName)+" ")]),_c('v-card-text',{class:{
        'pb-2': _vm.person.phone || _vm.person.mail || _vm.person.fax,
        'px-2': _vm.$vuetify.breakpoint.xs,
        'px-3': _vm.$vuetify.breakpoint.sm
      }},[_c('v-row',[_c('v-col',{class:{
            'text-subtitle-2': _vm.$vuetify.breakpoint.xs
          }},[_vm._v(" "+_vm._s(_vm.person.title)+" ")])],1),(_vm.person.phone || _vm.person.mail || _vm.person.fax)?_c('v-row',{staticClass:"pb-4 mt-2"},[(_vm.person.phone)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('ContactChip',{attrs:{"icon":"mdi-phone","value":_vm.person.phone,"href":'tel:' + _vm.cleanPhoneNumber(_vm.person.phone)}})],1):_vm._e(),(_vm.person.mail)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('ContactChip',{attrs:{"icon":"mdi-email","value":_vm.person.mail,"href":'mailto:' + _vm.person.mail}})],1):_vm._e(),(_vm.person.fax)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('ContactChip',{attrs:{"icon":"mdi-fax","value":_vm.person.fax,"href":null}})],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }