<template>
  <v-sheet
    color="#0000001A"
    class="pa-2"
    rounded
  >
    <v-card
      light
      class="neutral--text"
      elevation="0"
    >
      <v-img
        :aspect-ratio="4/5"
        :src="person.img"
      >
        <template v-slot:placeholder>
          <v-sheet
            color="#eee"
            tile
            class="fill-height"
          >
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-icon
                size="120"
              >
                mdi-account
              </v-icon>
            </v-row>
          </v-sheet>
        </template>
      </v-img>
      <v-card-title
        class="font-weight-bold pb-0"
        :class="{
          'text-body-2': $vuetify.breakpoint.xs,
          'text-body-1': $vuetify.breakpoint.sm,
          'px-2': $vuetify.breakpoint.xs,
          'px-3': $vuetify.breakpoint.sm
        }"
      >
        {{ person.fullName }}
      </v-card-title>
      <v-card-text
        :class="{
          'pb-2': person.phone || person.mail || person.fax,
          'px-2': $vuetify.breakpoint.xs,
          'px-3': $vuetify.breakpoint.sm
        }"
      >
        <v-row>
          <v-col
            :class="{
              'text-subtitle-2': $vuetify.breakpoint.xs
            }"
          >
          {{ person.title }}
          </v-col>
        </v-row>
        <v-row
          v-if="person.phone || person.mail || person.fax"
          class="pb-4 mt-2"
        >
          <v-col
            v-if="person.phone"
            cols="12"
            class="py-1"
          >
            <ContactChip
              icon="mdi-phone"
              :value="person.phone"
              :href="'tel:' + cleanPhoneNumber(person.phone)"
            />
          </v-col>
          <v-col
            v-if="person.mail"
            cols="12"
            class="py-1"
          >
            <ContactChip
              icon="mdi-email"
              :value="person.mail"
              :href="'mailto:' + person.mail"
            />
          </v-col>
          <v-col
            v-if="person.fax"
            cols="12"
            class="py-1"
          >
            <ContactChip
              icon="mdi-fax"
              :value="person.fax"
              :href="null"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import ContactChip from '@/views/components/ContactChip.vue'

export default {
  name: 'Person',
  props: {
    person: Object
  },
  components: {
    ContactChip
  },
  data () {
    return { }
  },
  mounted () { },
  methods: {
    cleanPhoneNumber (phoneNumber) {
      let cleanPhoneNumber = phoneNumber.replace(' ', '')
      cleanPhoneNumber = phoneNumber.replace('/', '')
      cleanPhoneNumber = phoneNumber.replace('\\', '')
      cleanPhoneNumber = phoneNumber.replace('-', '')
      return cleanPhoneNumber
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .contact-chip {
    overflow: visible !important;
    padding-left: 1px !important;
  }
</style>
