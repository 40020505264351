var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentFrame',{attrs:{"themeColor":_vm.computedTopicColor,"title":_vm.computedTitle}},[_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Ein Vorwort","textArray":[
      'Der Datenschutz ist uns wichtig und wir nehmen ihn sehr ernst. Wir setzen auf eine vertrauensvolle Kooperation mit Ihnen und sind in jeder Hinsicht bemüht, Sie rundum zufriedenzustellen - dies gilt natürlich auch im Umgang mit Ihren persönlichen Daten. Mit diesem Dokument „Datenschutz“ möchten wir Sie darüber informieren, welche Daten im Rahmen der Nutzung der Webseite www.hans-lebrecht-schule.de erhoben, wie diese Daten verarbeitet werden und was Sie ggf. selbst zum besseren Schutz Ihrer Daten tun können.',
      'Für Angaben zum Betreiber der Plattform und der verantwortlichen Stelle im Sinne des Datenschutzes schauen Sie bitte ins Impressum. Sie finden den Link zum Impressum in der Kopfzeile der Webseite.',
      'Sie können sich bei Fragen jeglicher Art jederzeit über die im Impressum angegebene Kontaktadresse an uns wenden.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"1. Gültigkeit des Dokumentes „Datenschutz“","textArray":[
      'Dieses Dokument gilt für die Nutzung der Webseite www.hans-lebrecht-schule.de.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"2. Verantwortung","textArray":[
      'Verantwortlicher Anbieter der Webseite ist die Stadt Ulm. Die Webseite wird durch die Firma WIderstand und Soehne GmbH (widerstand.digital) zur Verfügung gestellt und gehostet.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"3. Gesetzesgrundlagen","textArray":[
      'Gesetzliche Grundlagen sind die EU-Datenschutzgrundverordnung (DS-GVO) sowie das Landesdatenschutzgesetz Baden-Württemberg (LDSG).',
      'Mit der Zustimmung zu diesem Dokument willigen Sie auch ein, dass bei einem Betreiberwechsel Ihre Zustimmung zum Dokument wirksam bleibt und Ihre Daten an den kommenden Betreiber übergeben werden.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"4. Allgemeines zur Verarbeitung personenbezogener Daten","subtitle":"Was sind personenbezogene Daten?","textArray":[
      'Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Entscheidend ist also, ob durch die erhobenen Daten ein Personenbezug hergestellt werden kann. Darunter fallen Informationen wie z.B. Ihr Name, Ihre Anschrift, Ihre Telefonnummer, Mailadresse. Informationen, die nicht direkt mit Ihrer wirklichen Identität in Verbindung gebracht werden - wie z.B. favorisierte Webseiten oder Anzahl der Nutzer einer Seite - sind keine personenbezogenen Daten.',
      'Wir verarbeiten Ihre personenbezogenen Daten unter Einhaltung der Datenschutzgesetze der Bundesrepublik Deutschland und der Datenschutzbestimmungen der Europäischen Union. In keinem Fall werden wir Ihre personenbezogenen Daten an Dritte weitergeben.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"5. Datenerhebung und -speicherung","subtitle":"Beim Besuch der Webseite www.hans-lebrecht-schule.de","textArray":[
      'Beim Besuch der Webseite werden durch den Webseitenbetreiber in den Server-Logfiles folgende Daten gespeichert:'
    ]}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ul',[_c('li',[_vm._v(" Browsertyp und Browserversion ")]),_c('li',[_vm._v(" verwendetes Betriebssystem ")]),_c('li',[_vm._v(" Referrer URL ")]),_c('li',[_vm._v(" IP Adresse des zugreifenden Rechners ")]),_c('li',[_vm._v(" Uhrzeit der Serveranfrage ")])]),_c('br'),_vm._v(" Diese Daten können nicht bestimmten Personen zugeordnet werden. Die Erhebung dieser Daten dient nicht der Analyse der Besucherströme oder der Weiterverarbeitung, sondern werden benötigt, um z.B. Angriffe auf den Server festzustellen. Die Log-Dateien werden automatisiert nach 180 Tagen gelöscht. ")])]),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"6. Löschung Ihrer personenbezogenen Daten","textArray":[
      'Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn Sie ihre Einwilligung zur Speicherung widerrufen, wenn die Kenntnis der Daten zur Erfüllung des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich ist oder wenn die Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist. Daten für Abrechnungszwecke und buchhalterische Zwecke werden von einem Löschungsverlangen nicht berührt.',
      'Beim Besuch der Webseite www.hans-lebrecht-schule.de werden keine personenbezogenen Daten erhoben. Bei Nutzung der Kontaktadresse werden die Mails nach einem Jahr gelöscht. Sie können uns jederzeit anschreiben, wenn Sie möchten dass Ihre Mails gelöscht werden.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"7. Haftung","textArray":[
      'Die Stadt Ulm als Betreiberin stellt auf der Webseite www.hans-lebrecht-schule.de Informationen und Daten sowie mittels Hyperlinks (Internetverknüpfung) Informationen anderer Websites zur Verfügung. Diese Informationen und Daten dienen allein Informationszwecken, ohne dass sich auf die Aktualität, Richtigkeit oder Vollständigkeit der Informationen berufen oder verlassen werden kann. Die Stadt Ulm haftet weder für Inhalte, die über diese Plattform verbreitet werden, noch für Schäden die aus der Nutzung entstehen, es sei denn, dass solche Schäden von der Stadt Ulm oder einem ihrer Erfüllungsgehilfen vorsätzlich oder grob fahrlässig herbeigeführt werden. Dies gilt für alle Arten von Schäden, insbesondere Schäden, die durch Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung, bei Störungen der technischen Anlagen und des Services, unrichtige Inhalte, Verlust oder Löschung von Daten, Viren oder in sonstiger Weise bei der Nutzung dieses Online-Angebots entstehen können. Die Haftung für Folgeschäden ist ausgeschlossen. Für sämtliche Rechtsbeziehungen zwischen der Stadt Ulm und den Nutzer*innen gilt ausschließlich deutsches Recht unter Ausschluss des UN-Kaufrechts. Sollte eine oder mehrere Klauseln dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Gerichtstandort ist Ulm.',
      'Wir empfehlen Ihnen, sich bei allen Betreibern der verlinkten Internetseiten nach deren Richtlinien zum Datenschutz zu erkundigen.',
      'Alle in die Webseite eingebrachten Informationen und Materialien sind urheberrechtlich geschützt. Ihre Nutzung unterliegt den geltenden Urheberrechten. Urheber der Inhalte ist die Hans-Lebrecht-Schule. Die Inhalte dürfen ohne Zustimmung der Autor*innen nicht weiterverwendet werden. Insbesondere sind der Verkauf und jegliche kommerzielle Nutzung unzulässig.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"8. Cookies","textArray":[
      'Cookies sind Identifizierungszeichen, die auf der Festplatte der Nutzer*innen der Plattform gespeichert werden. Beim Besuch der Webseite https://www.hans-lebrecht-schule.de/ wird Ihre Zustimmung zur Nutzung von Cookies erfragt.',
      'Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:'
    ]}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ul',[_c('li',[_vm._v(" Transiente Cookies: Werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen. ")]),_c('li',[_vm._v(" Persistente Cookies: Werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen. ")])]),_c('br'),_vm._v(" Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können. ")])]),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"9. Ihre Datenschutzrechte","textArray":[
      'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und mögliche Empfänger und den Zweck der Datenverarbeitung (Art. 15 DSGVO) und ggf. ein Recht auf Berichtigung unrichtiger Daten Art. 16 DSGVO), Löschung dieser Daten (Art. 17 DSGVO) das Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, auf Widerspruch (Art. 21 DSGVO) sowie das Recht auf Datenübertragbarkeit von Ihnen bereitgestellter Daten nach Art. 20 DSGVO). Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen nach §§ 34 und 35 BDSG.',
      'Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (EU-DSGVO) ist Frau Lucia Geitmann, lucia.geitmann@uniklinik-ulm.de.',
      'Den zuständigen Datenschutzbeauftragten erreichen Sie unter datenschutz@ssa-bc.kv.bwl.de oder unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.',
      'Darüber hinaus steht Ihnen im Falle datenschutzrechtlicher Verstöße ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu (Art. 77 DSGVO i.V.m. §19 BDSG). Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem die Institution ihren Sitz hat. Die zuständige Aufsichtsbehörde ist demnach in datenschutzrechtlichen Fragen der Landesdatenschutzbeauftragte für den Datenschutz und die Informationsfreiheit in Baden-Württemberg, Postfach 103299, 70025 Stuttgart, 0711/615541–0, poststelle@lfdi.bwl.de.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"10. Analysesoftware","textArray":[
      'Es wird bei Nutzung der Webseite keine Analysesoftware genutzt.'
    ]}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"11. Änderungen des Dokumentes „Datenschutz“","textArray":[
      'Es können Änderungen an diesen Datenschutzhinweisen vorgenommen werden, die auf dieser Seite bekanntgegeben werden.'
    ],"downloadText":"Informationen zu \"Datenschutz\"","downloadFileName":"Flyer_Abteilung_Unterricht_am_Krankenbett.pdf"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }